export function setActiveNavState(navItemString) {
	const navItems = document.querySelectorAll(".header .header-items li");
	// Check if active state exists
	if (!document.querySelector(".header-selected")) {
		// Get matching nav item
		const activeNavItem = [...navItems].find((li) => li.innerText === navItemString);
		// If a match is found
		if (activeNavItem) {
			activeNavItem.querySelector("a").classList.add("header-selected");
		}
	}
}
