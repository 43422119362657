(function () {

    'use strict';

    $(document).ready(function () {

        var local_selectedInvestorTypeUrl = localStorage.selectedInvestorTypeUrl;

        if (typeof local_selectedInvestorTypeUrl === 'undefined') {

            $('.investor-type-box').removeClass('hidden');

        }

        $(document).on('click', '.dropdown-backdrop', function () {

            $('html').removeClass('mobile-dropdown-toggle-active');

        });

        $(document).on('click', 'html.mobile-dropdown-toggle-active', function () {

            $('html').removeClass('mobile-dropdown-toggle-active');

        });

        var namespace = {

            scrollToScrollID: function () {

                // Get ID from URL.
                var hashID = window.location.hash.substr(1),
                    // Check for element with this data value.
                    scrollElem = $('li[data-scroll-id="' + hashID + '"]');

                if (scrollElem.length > 1) {

                    // Warn of duplicate data-scroll-id's.
                    window.console.warn('GLOBAL.JS -- Duplicate data-scroll-id\'s found on the page. Auto-scroll did not execute.');

                }
            },

            numberFormattingHelper: function () {

                var formatFunctions = {

                    toRandFormat: function (amount) {
                        if (window.accounting) {
                            return window.accounting.formatMoney(amount, 'R', 2, ',', '.');
                        }

                    },

                    toRandFormatShort: function (amount) {
                        if (window.accounting) {
                            return window.accounting.formatMoney(amount, 'R', 0, ',', '.');
                        }

                    }

                };

                window.formatNumber = {};
                window.formatNumber = formatFunctions;

            },

            form1080submissionHandler: function () {

                $('#form-1080').off('submit').on('submit', function (e) {

                    e.preventDefault();

                    var $this = $(this),
                        isvalidate = $('#form-1080').valid();

                    if (isvalidate) {


                        $.ajax({
                            type: 'POST',
                            url: $this.attr('action'),
                            data: $this.serialize(),
                            success: function (data) {

                                $this.empty().html('<h3 class="submission-response-msg">' + data + '</h3>');

                            }

                        });

                    }

                    return false;

                });

            },

            investorTypeModal: function () {

                $('.investor-type-box #option01').on('click', 'li a', function () {

                    var anchor = $(this),
                        displayText = anchor.text();

                    $('#option01 ul li').removeClass('selected');

                    anchor.parent().addClass('selected');

                    $('#option01 #dropdown-title').html(displayText).css({ 'color': '#2f2f2f' });

                });


                $('.investor-type-box .cookie-policy-text a').click(function (e) {

                    e.preventDefault();

                    var selectedInvestor = $('#option01 ul li.selected a').attr('data-url');

                    window.location.href = selectedInvestor + 'terms-and-conditions/';

                });

            }
        };

        /*
         * This function will catch all form elements blur events.
         */
        $('textarea, select, option, input[type="text"], input[type="number"], input[type="email"], input[type="submit"]').off().on('blur', function () {

            if ($(this).val()) {

                window.gaTracking.sendEvent('Submit', $(this).closest('form').attr('id'), $(this).siblings('label').text(), $(this).val(), '', '');

            }

        });

        /*
         * This function will catch all the sharing links for the insights articles.
         */
        $('.article-content .share span').off().on('click', function () {

            // console.log($(this));

            window.gaTracking.sendEvent('Click', 'Market & Fund commentary', 'Share', $(this).attr('displaytext'), '', '');

        });

        /*
         * This function will catch all links, form elements
         */
        $('a, button, input[type="button"], input[type="submit"]').off().on('click', function (e) {

            if ($(e.target).is('input[type="submit"]')) {

                return;
                // console.log('Submitting form...');

            }

            if ($(e.target).is('input[type="text"], input[type="textarea"]')) {

                // These are handled as a 'onblur' event.
                return;

            }

            var $this = $(this),
                href = (typeof ($this.attr('href')) !== 'undefined') ? $this.attr('href') : '',
                isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]),
                filetypes = /\.(csv|zip|exe|dmg|pdf|doc.*|xls.*|ppt.*|mp3|txt|rar|wma|mov|avi|wmv|flv|wav)$/i;


            // If the clicked aHref is a hash.
            if ($this['0'].hash === '#') {

                // We don't need to continue.
                return;

            }

            // If the clicked aHref has a hash from the light box feature...
            if ($this['0'].hash === '#openLightBox' || $this['0'].hash === '#closeLightBox') {

                // We don't need to continue.
                return;

            }

            // Search queries...
            if (e.target.id === 'SearchBtn' || e.target.id === 'footerSearchBtn') {

                return;

            }

            if (href.match(/#/)) {

                return;

            }

            if (!href.match(/^javascript:/i) || !href.match(/^#/i)) {

                // Define our Google Analytic Event object, where we will hold our collected info
                var gaEventInfo = [],
                    hardcodedUmracoUrl = 'www.prudential.co.za';

                gaEventInfo.value = '';

                if (href.match(/^mailto:/i)) {

                    gaEventInfo.eventtype = 'Click';
                    gaEventInfo.category = 'ContactDetails';
                    gaEventInfo.action = 'Moving to default mail client';
                    gaEventInfo.label = href.replace(/^mailto:/i, '');

                } else if (href.match(/^tel:/i)) {

                    gaEventInfo.eventtype = 'Click';
                    gaEventInfo.category = 'ContactDetails';
                    gaEventInfo.action = 'Click';
                    gaEventInfo.label = href.replace(/^tel:/i, '');

                } else if (href.match(filetypes)) {

                    // Strip out just the extension.
                    var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;

                    gaEventInfo.eventtype = 'Download';
                    gaEventInfo.category = href.slice(href.lastIndexOf('/')).replace(' ', '-');
                    gaEventInfo.action = extension[0].charAt(0).toUpperCase() + extension[0].slice(1).toLowerCase() + ' Download';
                    gaEventInfo.label = window.location.pathname;

                } else if (href.match(/^https?:/i) && !isThisDomain && extractHostName(href) !== hardcodedUmracoUrl) {

                    gaEventInfo.eventtype = 'Click';
                    gaEventInfo.label = href.replace(/^https?:\/\//i, '');

                    if ((gaEventInfo.label === 'twitter.com/prudentialsa') || (gaEventInfo.label === 'www.facebook.com/prudentialinvestmentmanagers/') || (gaEventInfo.label === 'za.linkedin.com/company/prudential-portfolio-managers')) {

                        gaEventInfo.category = 'Social';

                    } else {

                        gaEventInfo.category = 'ExternalLink';

                    }

                    gaEventInfo.action = 'Click';

                } else if (extractHostName(href) === hardcodedUmracoUrl) {

                    gaEventInfo.eventtype = 'Click';
                    gaEventInfo.category = href;
                    gaEventInfo.action = 'Page Navigation';
                    gaEventInfo.label = 'Internal Link';

                } else if ($this[0].nodeName === 'BUTTON') {

                    if ($this[0].parentElement.className.indexOf('dropdown') > -1) {
                        //if ($this[0].parentElement.className.includes('dropdown')) {

                        return;

                    }

                    // This button belongs to the fund-picker.
                    //if ($this[0].parentElement.className.includes('fund-picker-controls') || $this[0].innerText === 'FIND FUNDS' || $this[0].innerText === 'RESET' || $this[0].innerText === 'PRINT') {
                    if (($this[0].parentElement.className.indexOf('fund-picker-controls') > -1) || $this[0].innerText === 'FIND FUNDS' || $this[0].innerText === 'RESET' || $this[0].innerText === 'PRINT') {

                        gaEventInfo.category = 'FundPicker';

                        if ($this[0].innerText === 'FIND FUNDS' || $this[0].innerText === 'RESET' || $this[0].innerText === 'PRINT') {

                            gaEventInfo.eventtype = 'Submit';

                        } else {

                            gaEventInfo.eventtype = 'Click';

                        }

                        gaEventInfo.action = 'Click';
                        gaEventInfo.label = $this[0].innerText;

                    }

                    // This button belongs to the slide shows.
                    //if ($this[0].parentElement.className.includes('slider-menu-buttons')){
                    if ($this[0].parentElement.className.indexOf('slider-menu-buttons') > -1) {

                        gaEventInfo.eventtype = 'Click';
                        gaEventInfo.action = 'Slide Change';
                        gaEventInfo.label = $this[0].innerText;
                        gaEventInfo.category = (href.length !== 0) ? href : window.location.pathname;
                    }

                    // This button belongs to the slide shows.
                    if ($this[0].className.indexOf('up-vote') > -1) {

                        gaEventInfo.eventtype = 'Submit';
                        gaEventInfo.action = 'Click';
                        gaEventInfo.label = 'Up Vote';
                        gaEventInfo.category = 'Social';
                    }


                    // This button belongs to the slide shows.
                    if ($this[0].className.indexOf('down-vote') > -1) {

                        gaEventInfo.eventtype = 'Submit';
                        gaEventInfo.action = 'Click';
                        gaEventInfo.label = 'Down Vote';
                        gaEventInfo.category = 'Social';
                    }

                } else {

                    // This is the the default page links.
                    gaEventInfo.eventtype = 'Click';
                    gaEventInfo.category = (href.length !== 0) ? href : window.location.pathname;
                    gaEventInfo.action = 'Page Navigation';
                    gaEventInfo.label = 'Internal Link';

                }

                window.gaTracking.sendEvent(
                    gaEventInfo.eventtype,
                    gaEventInfo.category,
                    gaEventInfo.action,
                    gaEventInfo.label,
                    gaEventInfo.value,
                    href
                );

            }
            // END: check for '#' & 'javascript:' hRef's.

        });



        /**
         * The Google GTM tracking class.
         * @class GATrackingClass
         * @method constructor
         */
        var GATrackingClass = function () {

            /**
             * Sends a Google Analytics event object in the following format:
             * window.dataLayer('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
             *
             *  Example:
             *
             *   window.dataLayer('send', {
             *     hitType: 'event',
             *     eventCategory: 'Videos',
             *     eventAction: 'play',
             *     eventLabel: 'Some Campaign'
             *   });
             * @param eventType {string} The event type(e.g. 'click', 'download') required
             * @param eventCategory {string} Url after domain (EG: homepage > Featured fund). required
             * @param eventAction {string} Event Clicked element details. required
             * @param eventLabel {string} Value of input or name of button. required
             * @param eventValue {int} A numeric value associated with the event (e.g. 42) Optional
             * @param newURL {string} A url to navigate to if the url is done programmatically.
             */
            this.sendEvent = function (eventType, eventCategory, eventAction, eventLabel, eventValue, newURL) {

                if (typeof eventCategory !== 'undefined') {

                    if (eventCategory.match(/#/)) {

                        return;

                    }

                } else {

                    return;

                }

                // 'newURL' is an optional value, so we set it here.
                newURL = newURL || null;

                var eventCategoryFriendlyname = '';

                if (eventCategory !== 'FundPicker') {

                    var eventCategoryArray = eventCategory.replace('//', '/').replace(/\/$/, '').split('/'),
                        i = 0;

                    for (i; i < eventCategoryArray.length; i += 1) {

                        if (i > 1) {

                            eventCategoryFriendlyname += ' > ';

                        }

                        eventCategoryFriendlyname += eventCategoryArray[i];
                    }

                }

                var eventObject = {
                    event: 'custom',
                    event_type: eventType,
                    event_category: (eventLabel === 'External Link' || eventType === 'Download') ? eventCategory.replace('//', '/').replace(' ', '') : ((eventCategoryFriendlyname !== '') ? eventCategoryFriendlyname : eventCategory),
                    event_action: eventAction,
                    event_label: eventLabel,
                    event_value: eventValue
                };

                if (eventObject.event_category !== '' || eventObject.event_category !== '#') {

                    console.dir(eventObject);

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(eventObject);

                } else {

                    console.error('This should be a link that has functionality only for this pages DOM Objects, EG: an accordian...');

                }

            };

        };

        /**
         * Create a new instance of the GATrackingClass.
         * @type {GATrackingClass} A object containing the functionality to manipulate the Google Analytics object.
         */
        window.gaTracking = new GATrackingClass();

        // Namespace loader
        for (var i in namespace) {

            if (namespace[i].hasOwnProperty) {

                namespace[i]();

            }

        }

        /**
         *
         * @param url
         * @returns {string} hostName
         */
        function extractHostName(url) {

            var hostName;

            // Find & remove protocol (http, ftp, etc.) and get hostName
            if (url.indexOf("//") > -1) {
                hostName = url.split('/')[2];
            } else {
                hostName = url.split('/')[0];
            }

            // Find & remove port number.
            hostName = hostName.split(':')[0];
            // Find & remove "?".
            hostName = hostName.split('?')[0];

            return hostName;

        }

    });

})();

$(document).ready(function () {
    $("ul.fund-details-tabs li").click(function () {
        var tab_id = $(this).attr("data-tab");

        $("ul.fund-details-tabs li").removeClass("current");
        $(".tab-content").removeClass("current");

        $(this).addClass("current");
        $("#" + tab_id).addClass("current");
    });
});

//Dropdown

function DropDown(el) {
    this.dd = el;
    this.placeholder = this.dd.children("span");
    this.opts = this.dd.find("ul.dropdown > li");
    this.val = "";
    this.index = -1;
    this.initEvents();
}
DropDown.prototype = {
    initEvents: function () {
        var obj = this;

        obj.dd.on("click", function (event) {
            $(this).toggleClass("active");
            return false;
        });

        obj.opts.on("click", function () {
            var opt = $(this);
            obj.val = opt.text();
            obj.index = opt.index();
            obj.placeholder.text(obj.val);
        });
    },
    getValue: function () {
        return this.val;
    },
    getIndex: function () {
        return this.index;
    }
};


/* Nav Active States */

import { setActiveNavState } from "/src/js/helpers/setActiveNavState"
document.addEventListener('DOMContentLoaded', () => {
    const url = window.location.href
    if (url.includes('/invest-now/')) {
        return setActiveNavState('Invest with Us')
    }
    if (url.includes('/tools/')) {
        return setActiveNavState('Tools')
    }
    if (url.includes('/our-funds/')) {
        return setActiveNavState('Our Funds')
    }
    if (url.includes('/about-us/')) {
        return setActiveNavState('About Us')
    }
    if (url.includes('/insights/')) {
        return setActiveNavState('Insights')
    }
    if (url.includes('/contact-us/')) {
        return setActiveNavState('Contact Us')
    }
    if (url.includes('/becoming-mandg')) {
        return setActiveNavState('About Us')
    }
})